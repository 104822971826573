import React from 'react'

export function FreeBanner({isFree}) {
  
  return (
  		isFree ? <div class="free-banner-div"><p>Free</p></div> : null
  )
}

export default FreeBanner
