import React, {useState, useEffect} from "react";

const TimePicker = ({selectedValue, isEnabled, onUpdate}) => {

    const [isPickerEnabled, setIsPickerEnabled] = useState(isEnabled);

    useEffect(() => {
        setIsPickerEnabled(isEnabled);
      }, [isEnabled]);

    const onChange = event => {
      if (onUpdate) {
        let newValue = event?.target?.value;
        onUpdate(newValue);
      }
    }

    return (
      
      <select disabled={!isPickerEnabled} defaultValue={selectedValue} className="form-select" aria-label="Select a time" onChange={onChange} >
        <option value=""></option>
        <option value="T06:00:00Z">06:00 am</option>
        <option value="T06:15:00Z">06:15 am</option>
        <option value="T06:30:00Z">06:30 am</option>
        <option value="T06:45:00Z">06:45 am</option>
        <option value="T07:00:00Z">07:00 am</option>
        <option value="T07:15:00Z">07:15 am</option>
        <option value="T07:30:00Z">07:30 am</option>
        <option value="T07:45:00Z">07:45 am</option>
        <option value="T08:00:00Z">08:00 am</option>
        <option value="T08:15:00Z">08:15 am</option>
        <option value="T08:30:00Z">08:30 am</option>
        <option value="T08:45:00Z">08:45 am</option>
        <option value="T09:00:00Z">09:00 am</option>
        <option value="T09:15:00Z">09:15 am</option>
        <option value="T09:30:00Z">09:30 am</option>
        <option value="T09:45:00Z">09:45 am</option>
        <option value="T10:00:00Z">10:00 am</option>
        <option value="T10:15:00Z">10:15 am</option>
        <option value="T10:30:00Z">10:30 am</option>
        <option value="T10:45:00Z">10:45 am</option>
        <option value="T11:00:00Z">11:00 am</option>
        <option value="T11:15:00Z">11:15 am</option>
        <option value="T11:30:00Z">11:30 am</option>
        <option value="T11:45:00Z">11:45 am</option>
        <option value="T12:00:00Z">12:00 pm</option>
        <option value="T12:15:00Z">12:15 pm</option>
        <option value="T12:30:00Z">12:30 pm</option>
        <option value="T12:45:00Z">12:45 pm</option>
        <option value="T13:00:00Z">1:00 pm</option>
        <option value="T13:15:00Z">1:15 pm</option>
        <option value="T13:30:00Z">1:30 pm</option>
        <option value="T13:45:00Z">1:45 pm</option>
        <option value="T14:00:00Z">2:00 pm</option>
        <option value="T14:15:00Z">2:15 pm</option>
        <option value="T14:30:00Z">2:30 pm</option>
        <option value="T14:45:00Z">2:45 pm</option>
        <option value="T15:00:00Z">3:00 pm</option>
        <option value="T15:15:00Z">3:15 pm</option>
        <option value="T15:30:00Z">3:30 pm</option>
        <option value="T15:45:00Z">3:45 pm</option>
        <option value="T16:00:00Z">4:00 pm</option>
        <option value="T16:15:00Z">4:15 pm</option>
        <option value="T16:30:00Z">4:30 pm</option>
        <option value="T16:45:00Z">4:45 pm</option>
        <option value="T17:00:00Z">5:00 pm</option>
        <option value="T17:15:00Z">5:15 pm</option>
        <option value="T17:30:00Z">5:30 pm</option>
        <option value="T17:45:00Z">5:45 pm</option>
        <option value="T18:00:00Z">6:00 pm</option>
        <option value="T18:15:00Z">6:15 pm</option>
        <option value="T18:30:00Z">6:30 pm</option>
        <option value="T18:45:00Z">6:45 pm</option>
        <option value="T19:00:00Z">7:00 pm</option>
        <option value="T19:15:00Z">7:15 pm</option>
        <option value="T19:30:00Z">7:30 pm</option>
        <option value="T19:45:00Z">7:45 pm</option>
        <option value="T20:00:00Z">8:00 pm</option>
        <option value="T20:15:00Z">8:15 pm</option>
        <option value="T20:30:00Z">8:30 pm</option>
        <option value="T20:45:00Z">8:45 pm</option>
        <option value="T21:00:00Z">9:00 pm</option>
        <option value="T21:15:00Z">9:15 pm</option>
        <option value="T21:30:00Z">9:30 pm</option>
        <option value="T21:45:00Z">9:45 pm</option>
        <option value="T22:00:00Z">10:00 pm</option>
      </select>
  );
}

export default TimePicker

