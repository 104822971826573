import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_BACKEND_URL;


class ActivityServices {
  getPublicContent() {
    return axios.get(API_URL + 'users/me', { headers: authHeader() });
  }

  getActivities(data) {
    return axios.post(API_URL + 'activities/discover', data, { headers: authHeader() });
  }

  getActivity(activityId) {
    return axios.get(API_URL + `activities/${activityId}`, { headers: authHeader() });
  }

  createActivity(data) {
    console.log(`creating activity: ${JSON.stringify(data)}`);
    return axios.post(API_URL + `activities`, data, { headers: authHeader() });
  }

  updateActivity(activityId, data) {
    console.log(`updating activity: [${activityId}] data: ${JSON.stringify(data)}`);
    return axios.put(API_URL + `activities/${activityId}`, data, { headers: authHeader() });
  }

  getMyActivities(userId) {
    return axios.get(API_URL + `activities?user.id=${userId}&_sort=placeName,name`, { headers: authHeader() });
  }

  getPreviewActivities(data) {
    return axios.get(API_URL + 'activity-collections');
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
}

export default new ActivityServices();
