import React, {useState, useEffect} from 'react'

export function ValidatingInput({type, label, placeholder, validationError, onChange, value, limit, disabled, onIsValidChanged}) {
  	const EMPTY_STRING = "no-text";
  	const [isValueValid, setIsValueValid] = useState();

  	useEffect(() =>  {
		let valid = value !== EMPTY_STRING && value?.length > 0
		setIsValueValid(valid);
		
	  	if (onIsValidChanged) {
	  		onIsValidChanged(valid);
	  	}

  	}, [value]);

	const getIsValid = () => {

		return isValueValid ? "" : "is-invalid";

	}

	const getLimit = () => {
		return limit ? limit : null;
	}

	const onValueChanged = (event) => {
		if (onChange) {
			onChange(event);
		}
	}

	return (
			<div className="input-group has-validation">
					<form className="form-floating mb3">
						{type === "textarea" ? (
							<textarea disabled={disabled} maxLength={getLimit()} type="textarea" onChange={onValueChanged} className={`form-control textarea ${getIsValid()}`} id={label} placeholder={placeholder} defaultValue={value}/>
						) : (
					  	<input type="text" disabled={disabled} onChange={onChange} className={`form-control ${getIsValid()}`} id={label} placeholder={placeholder} value={value} required />
						)}

						{isValueValid ? (
						<label htmlFor={label}>{label}</label>
						) : (
					  <label className="validation-error-label" htmlFor={label}>{validationError}</label>
					 )}
				</form>
			</div>
	);
}

export default ValidatingInput
