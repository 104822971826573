import React from 'react'

export function AlertBanner({
  hasSuccess,
  hasErrored,
  successText,
  hasErroredText
}) {

  return (
    	<div className="alert-banner">
        {hasSuccess ? (
        <div className="alert alert-success alert-button alert-dismissible fade show" role="alert">
          <div>{successText}</div>
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div> ) : null}
        {hasErrored ? (
        <div className="alert alert-danger alert-button alert-dismissible fade show" role="alert">
          <div>{hasErroredText}</div>
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div> ) : null}
      </div>
  )
}

export default AlertBanner
