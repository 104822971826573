import React, {useState} from "react";

import { IoMdTime } from 'react-icons/io';

import Headline from './headline.component';
import ActivityOneOff from './activity-one-off.component';
import ActivityOneOffDates from './activity-one-off-dates.component';
import OpeningHours from './opening-hours.component';

const EMPTY_STRING = "no-text";

const ActivityOpeningHours = (props) => {
  
    const [activity] = useState(props?.activity);
    const [openingHours, setOpeningHours] = useState(activity?.openingHours);
    const [updatedOneOffActivity, setUpdatedOneOffActivity] = useState(EMPTY_STRING);
    const [updatedActivityStartDate, setUpdatedActivityStartDate] = useState(EMPTY_STRING);
    const [updatedActivityStartTime, setUpdatedActivityStartTime] = useState(EMPTY_STRING);
    const [updatedActivityEndDate, setUpdatedActivityEndDate] = React.useState(EMPTY_STRING);
    const [updatedActivityEndTime, setUpdatedActivityEndTime] = useState(EMPTY_STRING);
    const [updatedOpeningHours, setUpdatedOpeningHours] = useState(EMPTY_STRING);

  
    const getIsActivityOneOff = () => {
      return updatedOneOffActivity !== EMPTY_STRING ? updatedOneOffActivity : hasActivityStartDate();
    }

    const hasActivityStartDate = () => {
      return getActivityStartDate() !== undefined;
    }

    const updateIsActivityOneOff = (isOneOff) => {
      setUpdatedOneOffActivity(isOneOff);
      if (props.onUpdateIsActivityOneOffChange) {
        props.onUpdateIsActivityOneOffChange(isOneOff);
      }
      
    }

    const getActivityStartDate = () => {
      let startDateTime =
        updatedActivityStartDate !== EMPTY_STRING
          ? updatedActivityStartDate
          : activity?.startDateTime;
        return startDateTime !== undefined && startDateTime !== null ? new Date(startDateTime) : undefined;
      }

    const getActivityEndDate = () => {
      let endDateTime =
        updatedActivityEndDate !== EMPTY_STRING
          ? updatedActivityEndDate
          : activity?.endDateTime;
      return endDateTime !== undefined && endDateTime !== null ? new Date(endDateTime) : undefined;      
    }

    const updateActivityOneOffDate = event => {
      if (props.onOneOffDateChange) {
        props.onOneOffDateChange(event);
      }
      const startDate = event?.startDate;
      setUpdatedActivityStartDate(startDate);

      const endDate = event?.endDate;
      setUpdatedActivityEndDate(endDate);
    }


    const updateOneOffStartTime = time => {
      setUpdatedActivityStartTime(time);
      if (props.onOneOffStartTimeChange) {
        props.onOneOffStartTimeChange(time);
      }
    }

    const updateOneOffEndTime = time => {
      setUpdatedActivityEndTime(time);
      if (props.onOneOffEndTimeChange) {
        props.onOneOffEndTimeChange(time);
      }
    }

    const getStartDateTime = () => {
      let newDateTime = getActivityStartDate();
      return dateWithUpdatedHours(newDateTime, updatedActivityStartTime);
    }

    const getEndDateTime = () => {
      let newDateTime = getActivityEndDate();
      return dateWithUpdatedHours(newDateTime, updatedActivityEndTime);
    }

    const getOpeningHours = () => {
      if (updatedOpeningHours !== EMPTY_STRING) {
        return updatedOpeningHours;
      } else {
        return activity?.openingHours !== undefined ? activity?.openingHours : [];
      }
    }

    const updateOpeningHours = (event) => {
      setUpdatedOpeningHours(event);
      if (props.onOpeningHoursChange) {
        props.onOpeningHoursChange(event);
      }
    }

    //T10:15:00Z => 10
    const getHours = time => {
      let timePortion = time.split(":")
      return timePortion[0].substring(1);
    }

    //T10:15:00Z => 15
    const getMinutes = time => {
      let timePortion = time.split(":")
      return timePortion[1];
    }

    const dateWithUpdatedHours = (newDateTime, updatedTime) => {
      let result = newDateTime !== undefined ? newDateTime : getNewDate();
      if (updatedTime !== EMPTY_STRING) {
        result.setHours(getHours(updatedTime), getMinutes(updatedTime), 0, 0);
      }
      return result;
    }

    const getNewDate = () => {
      let result = new Date();
      result.setHours(0,0,0,0);
      return result;
    }

    return (
        <div>
          <Headline label="Opening Hours"><IoMdTime size={30} /></Headline>
           
          <div className="row">
            <div className="col">
                <ActivityOneOff
                  isActivityOneOff={getIsActivityOneOff()}
                  onChange={v => updateIsActivityOneOff(v)} />
            </div>
          </div>
          {getIsActivityOneOff() ? (
            <div className="row">
              <div className="col">
                  <ActivityOneOffDates startDate={getActivityStartDate()} endDate={getActivityEndDate()} 
                  onDateChange={v => updateActivityOneOffDate(v)}
                  onStartTimeChange={t => updateOneOffStartTime(t)} 
                  onEndTimeChange={t => updateOneOffEndTime(t)} />
              </div>
            </div>
            ) : (
              <OpeningHours openingHours={getOpeningHours()} onChange={e => updateOpeningHours(e)}/>
            )}
        </div>
      );
}

export default ActivityOpeningHours
