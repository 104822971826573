import React from 'react'
import { BsCheckCircle, BsCircle } from 'react-icons/bs';

export function ActivityIsFree({
  isActivityFree,
  onChange,
}) {

  return (
    	<div>
        <p className="lead">Is the activity free?</p>
        <span style={{"paddingBottom" : "10px"}} />
        <div className="row">
          <div className="col-2">
        		<button type="button" className={isActivityFree ? "btn btn-primary text-white active" : "btn btn-secondary text-white" }  aria-pressed={isActivityFree} onClick={() => onChange(true)} data-bs-toggle="button"> 
              {isActivityFree ? <BsCheckCircle size={18} /> : <BsCircle size={18} />}
              <span style={{"padding": "5px", "marginLleft": "10px"}}>Yes</span>
            </button>
            <span style={{"paddingLeft" : "20px"}} />
            <button type="button" className={!isActivityFree ? "btn btn-primary text-white active" : "btn btn-secondary text-white" }  aria-pressed={!isActivityFree} onClick={() => onChange(false)} data-bs-toggle="button"> 
              {!isActivityFree ? <BsCheckCircle size={18} /> : <BsCircle size={18} />}
              <span style={{"padding": "5px", "marginLeft": "10px"}}>No</span>
            </button>
          </div>
        </div>
    	</div>
  )
}

export default ActivityIsFree
