import React from "react";

const ActivityBanner = ({activity}) => {
    return (
      
      <p>{activity.reviewName !== "Just Visiting" ? `${activity.reviewName} @` : null} {activity.placeName}, {activity.addressLocality}</p>
    );
}

export default ActivityBanner

