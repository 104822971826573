import React from 'react'

export function Headline({label, children}) {
  
  return (
      <div>
  		  <hr/>
        {children ? (
        <div className="row">
          <div className="col-1 flex-end">
            {children}
          </div>
          <div className="col" style={{"marginLeft" : "-20px", "paddingBottom" : "20px"}}>
            <h4>{label}</h4>
          </div>
        </div>
        ) : (
          <div className="row">
            <div className="col" style={{"paddingBottom" : "20px"}}>
              <h4>{label}</h4>
            </div>
          </div>
        )
      }
      </div>
  )
}

export default Headline
