import React, { Component } from "react";
import {
  Route,
  Routes,
  Link
} from "react-router-dom";
import * as ReactDOM from "react-dom/client";
import "./App.css";
import EventBus from "./common/EventBus";
import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import PreviewActivities from "./components/preview-activities.component";
import MyActivities from "./components/my-activities.component";
import ActivityDetails from "./components/activity-details.component";
import CreateActivity from "./components/create-activity.component";

import Footer from "./components/footer.component";

class App extends Component {

  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      currentUser: undefined,
    };
    AuthService.registerAuthenticationRedirect();

  }

  componentDidMount() {
    
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
      });
    }
    
    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      currentUser: undefined,
    });
  }

  

  render() {
    const { currentUser } = this.state;
   
    return (
      <div>
        <nav className="navbar navbar-expand navbar-light bg-transparent">
          <div className="container-fluid">
            <a className="navbar-brand" href="https://www.villije.com/">
              <img src="https://images.squarespace-cdn.com/content/v1/5f861c64b5b5300c1b41446c/b4e5661e-ab22-4c33-bb7f-1110a8ceb223/WebsiteLogoFlat.png?format=1500w" alt="Villije" className="d-inline-block align-text-top villije-logo-img" />
            </a>
            <div className="collapse navbar-collapse nav-root" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto me-5">
                {currentUser && (  
                <li className="nav-item me-2">
                  <Link to={"/activities"} className="nav-link link" onClick={this.logOut}>
                    Logout
                  </Link>
                </li>
                )}
                {currentUser && (
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="manageActivities" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Manage Activities
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="manageActivities">
                    <li className="nav-item me-2">
                      <Link to={"/my-activities"} className="nav-link link">
                        My Activities
                      </Link>
                    </li>
                    <li className="nav-item me-2">
                      <Link to={"/create-activity"} className="nav-link link">
                        Create Activity
                      </Link>
                    </li>
                  </ul>
                </li>
                )}
                {!currentUser && (
                <li className="nav-item me-2">
                  <Link to={"/login"} className="nav-link link">
                    Login
                  </Link>
                </li>
                )}
                <li className="nav-item me-2">
                  <a className="nav-link link" href="https://www.villije.com/">About</a>
                </li>
                <li className="nav-item me-2">
                  <Link to={"/activities"} className="nav-link link">
                    Activities
                  </Link>
                </li>
                <li className="nav-item me-2">
                  <a className="nav-link link" href="https://www.villije.com/contact">Contact us</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="container mt-3">
          <Routes>
            <Route path="/activities" element={<PreviewActivities />} />
            <Route path="/my-activities" element={<MyActivities />} />
            <Route path="/login" element={<Login />} />
            <Route path="/my-activities/activity/:id" element={<ActivityDetails />} />
            <Route path="/create-activity" element={<CreateActivity />} />
          </Routes>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
