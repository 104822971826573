import React from 'react'

import {useNavigate} from 'react-router-dom';

import ThumbnailImage from './thumbnail-image.component';
import Ratings from './ratings.component';
import { FaPencilAlt } from "react-icons/fa";

export function MyActivityDetail({activity}) {
  
  const navigate = useNavigate();

  const navigateToDetails = () => {
    navigate(`/my-activities/activity/${activity.id}`, { state: { activity : activity}});
  }

  const getShortDescription = (description) => {
    if (description?.length > 100) {
      return description.substring(0, 220) + "...";
    }
  }

  const getDisplayDate = (date) => {
    return new Intl.DateTimeFormat('default', {'day' : 'numeric', 'month' : 'numeric', 'year' : 'numeric', 'hour' : 'numeric', 'minute' : 'numeric', 'second' : 'numeric', 'hour12' : true}).format(new Date(date));
  }

  return (
      <tr className="table-row" onClick={() => navigateToDetails(activity)}>
          <td><ThumbnailImage url={activity?.banner?.formats?.thumbnail?.url} /></td>
          <td>{activity?.placeName}</td>
          <td>{activity?.name}</td>
          <td>{getShortDescription(activity?.description)}</td>
          <td><span className="ratings-list-container"><Ratings score={activity?.reviewAverageScore} count={activity?.reviewCount} /></span></td>
          <td>{getDisplayDate(activity?.createdAt)}</td>
          <td>{getDisplayDate(activity?.updatedAt)}</td>
          <td><span><FaPencilAlt size={20} onMouseOver={({target})=>target.style.color="#00A292"} onMouseOut={({target})=>target.style.color="black"}/></span></td>
      </tr>
  )
}

export default MyActivityDetail
