import React, {useEffect}  from 'react'
import useState from 'react-usestateref'

import {useNavigate, useParams} from 'react-router-dom';

import ActivityService from "../services/activity.service";

import Loading from "./loading.component";
import AlertBanner from "./alert.component";
import Categories from './categories.component';
import PlaceDetails from './place-details.component';
import ActivityDetailsForm from './activity-details-form.component';
import ContactInformation from './contact-info.component';
import ActivityOpeningHours from './activity-opening-hours.component';
import Facilities from './facilities.component';
import AccessibleFacilities from './facilities-accessible.component';
import ActivityPhotos from './activity-photos.component';
import TriStateButton from './tri-state-button.component';


function ActivityDetail({activity}) {

  const navigate = useNavigate();
  const EMPTY_STRING = "no-text";
 	const [isLoading, setIsLoading] = useState();
 	const [dataLoadError, setDataLoadError] = useState(false);
 	const [activityDetail, setActivityDetail] = useState();
  const [activityId, setActivityId] = useState();
  const [hasSavedActivity, setHasSavedActivity] = useState(false);
  const [hasErroredActivity, setHasErroredActivity] = useState(false);
  const [updatedActivityName, setUpdatedActivityName] = useState(EMPTY_STRING);
  const [updatedActivityDescription, setUpdatedActivityDescription] = useState(EMPTY_STRING);
  const [updatedIsFree, setUpdatedIsFree] = useState(EMPTY_STRING);
  const [updatedCategories, setUpdatedCategories] = useState(EMPTY_STRING);
  const [updatedOneOffActivity, setUpdatedOneOffActivity] = useState(EMPTY_STRING);
  const [updatedActivityStartDate, setUpdatedActivityStartDate] = useState(EMPTY_STRING);
  const [updatedActivityStartTime, setUpdatedActivityStartTime] = useState(EMPTY_STRING);
  const [updatedActivityEndDate, setUpdatedActivityEndDate] = React.useState(EMPTY_STRING);
  const [updatedActivityEndTime, setUpdatedActivityEndTime] = useState(EMPTY_STRING);
  const [updatedOpeningHours, setUpdatedOpeningHours] = React.useState(EMPTY_STRING);
  const [updatedFacilities, setUpdatedFacilities] = useState(EMPTY_STRING);
  const [updatedAccessibleFacilities, setUpdatedAccessibleFacilities] = useState(EMPTY_STRING);
  const [updatedAllAges, setUpdatedAllAges] = useState(EMPTY_STRING);
  const [updatedMinAge, setUpdatedMinAge] = useState(EMPTY_STRING)
  const [updatedMaxAge, setUpdatedMaxAge] = useState(EMPTY_STRING)
  const [updatedContactName, setUpdatedContactName] = useState(EMPTY_STRING)
  const [updatedContactEmail, setUpdatedContactEmail] = useState(EMPTY_STRING);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [updatedContactPhone, setUpdatedContactPhone] = useState(EMPTY_STRING)
  const [updatedContactWebsite, setUpdatedContactWebsite] = useState(EMPTY_STRING)
  const [updatedPhotos, setUpdatedPhotos, updatePhotosRef] = useState(EMPTY_STRING);
  const [existingPhotos, setExistingPhotos] = useState([])
  const [isActivityDetailsValid, setIsActivityDetailsValid] = useState(false);
  const [isPlaceDetailsValid, setIsPlaceDetailsValid] = useState(false);
	const { id } = useParams(); //Get the id parameter from the url (defined in App.js)

  useEffect(() =>  {
  	setDataLoadError(false);
    setActivityId(id);
    if (!id)  {
    	//TODO show error
    } else {
    		loadActivity(id);
    }
  }, [activity, id]);

  const loadActivity = (activityId) => {
   
    setIsLoading(true);
    setDataLoadError(false);
    setHasSavedActivity(false);
    setHasErroredActivity(false);
    ActivityService.getActivity(activityId).then(
      response => {
        setIsLoading(false);
        setActivityDetail(response?.data);
        mergeBannerAndPhotos(response?.data);
      }
    ).catch(error => {
    	setDataLoadError(true);
    }); 
  }

  const mergeBannerAndPhotos = (data) => {
  	if (data?.banner) {
  		setExistingPhotos([data?.banner, ...data?.photos]);
  	} else {
  		setExistingPhotos(data?.photos);
  	}
  	setUpdatedPhotos([]);
  }

  const updateActivity = async () => {
   
    setHasSavedActivity(false);
    setHasErroredActivity(false);
    const data = buildRequest();

    ActivityService.updateActivity(activityId, data).then(
      response => {
       	setHasSavedActivity(true);
       	setActivityDetail(response?.data);
       	mergeBannerAndPhotos(response?.data);
      }
    ).catch(function (error) {
    	setHasErroredActivity(true);
    }).finally(() => {
    	//do nothing
    }); 
  }

  const navigateToMyActitivities = () => {
    navigate(`/my-activities`);
  }

  const buildRequest = () => {
  	let data = {};
  	if (updatedActivityName !== EMPTY_STRING) {
  			data = {...data, name: updatedActivityName}
  	}
  	if (updatedActivityDescription !== EMPTY_STRING) {
  		data = {...data, description: updatedActivityDescription}
  	}
  	if (updatedMinAge !== EMPTY_STRING) {
  		data = {...data, ageRangeMin: updatedMinAge }
    }
    if (updatedMaxAge !== EMPTY_STRING) {
      data =  {...data, ageRangeMax: updatedMaxAge }
    }
    if (updatedAllAges !== EMPTY_STRING && updatedAllAges === true) {
    	data =  {...data, ageRangeMin: null, ageRangeMax: null }
    } 
    if (updatedIsFree !== EMPTY_STRING) {
    	data =  {...data, isFree: updatedIsFree }
    }
    if (updatedCategories !== EMPTY_STRING) {
    	data =  {...data, categories: updatedCategories }
    }
    if (updatedContactName !== EMPTY_STRING) {
    	data =  {...data, contactName: updatedContactName }
    } 
    if (updatedContactPhone !== EMPTY_STRING) {
    	data =  {...data, contactPhone: updatedContactPhone }
    } 
    if (updatedContactEmail !== EMPTY_STRING) {
    	data =  {...data, contactEmail: updatedContactEmail }
    }  
    if (updatedContactWebsite !== EMPTY_STRING) {
    	data =  {...data, url: updatedContactWebsite }
    } 
    if (updatedFacilities !== EMPTY_STRING) {
    	data =  {...data, facility: updatedFacilities }
    }
    if (updatedAccessibleFacilities !== EMPTY_STRING) {
    	data =  {...data, accessibilityFacility: updatedAccessibleFacilities }
    }
    if (getIsActivityOneOff()) {
    		data = {...data, openingHours : [], startDateTime : getStartDateTime(), endDateTime: getEndDateTime()}
    		setUpdatedOpeningHours(EMPTY_STRING);
    }
    if (updatedOpeningHours !== EMPTY_STRING) {
    	data = {...data, openingHours : updatedOpeningHours, startDateTime : null, endDateTime: null}
    	setUpdatedOneOffActivity(EMPTY_STRING);
    }

    if (updatedPhotos !== EMPTY_STRING && updatedPhotos?.length > 0) {
        let banner = updatedPhotos.splice(0, 1);
    	  data = {...data, banner : banner[0], photos : updatedPhotos}
      }
    	setUpdatedPhotos([]);
      return data;
  }


  const updateActivityName = (event) => {
  	setUpdatedActivityName(event);
  }

  const updateActivityDescription = (event) => {
  	setUpdatedActivityDescription(event);
  }

  const getCategories = () => {
  	if (updatedCategories !== EMPTY_STRING) {
  		return updatedCategories;
  	} else {
  		return activityDetail?.categories !== undefined ? activityDetail?.categories : [];
  	}
  }

  const updateCategories = (event) => {
  	if (event) {
  		let existingCategories = getCategories();

  		if (event.isSelected) {
  			setUpdatedCategories([event.id, ...existingCategories])
  		} else {
  			let newCategories = existingCategories.filter(category => category !== event.id)
  			setUpdatedCategories(newCategories);
  		}
  	}
  }

  const getIsActivityOneOff = () => {
  	return updatedOneOffActivity !== EMPTY_STRING ? updatedOneOffActivity : hasActivityStartDate();
  }

  const hasActivityStartDate = () => {
  	return getActivityStartDate() !== undefined;
  }

  const updateIsActivityOneOff = (isOneOff) => {
  	setUpdatedOneOffActivity(isOneOff);	
  }

  const getActivityStartDate = () => {
    let startDateTime =
      updatedActivityStartDate !== EMPTY_STRING
        ? updatedActivityStartDate
        : activityDetail?.startDateTime;
			return startDateTime !== undefined && startDateTime !== null ? new Date(startDateTime) : undefined;
		}

	const getActivityEndDate = () => {
    let endDateTime =
      updatedActivityEndDate !== EMPTY_STRING
        ? updatedActivityEndDate
        : activityDetail?.endDateTime;
		return endDateTime !== undefined && endDateTime !== null ? new Date(endDateTime) : undefined;      
  }

  const updateActivityOneOffDate = event => {
  	const startDate = event?.startDate;
    setUpdatedActivityStartDate(startDate);

    const endDate = event?.endDate;
    setUpdatedActivityEndDate(endDate);
  };

  const updateOneOffStartTime = time => {
  	setUpdatedActivityStartTime(time);
  }

  const updateOneOffEndTime = time => {
  	setUpdatedActivityEndTime(time);
  }

  const getStartDateTime = () => {
  	let newDateTime = getActivityStartDate();
  	return dateWithUpdatedHours(newDateTime, updatedActivityStartTime);
  }

  //T10:15:00Z => 10
  const getHours = time => {
  	let timePortion = time.split(":")
  	return timePortion[0].substring(1);
  }

  //T10:15:00Z => 15
  const getMinutes = time => {
  	let timePortion = time.split(":")
  	return timePortion[1];
  }

  const getEndDateTime = () => {
  	let newDateTime = getActivityEndDate();
  	return dateWithUpdatedHours(newDateTime, updatedActivityEndTime);
  }

  const dateWithUpdatedHours = (newDateTime, updatedTime) => {
  	let result = newDateTime !== undefined ? newDateTime : getNewDate();
  	if (updatedTime !== EMPTY_STRING) {
			result.setHours(getHours(updatedTime), getMinutes(updatedTime), 0, 0);
		}
  	return result;
  }

  const getNewDate = () => {
  	let result = new Date();
  	result.setHours(0,0,0,0);
  	return result;
  }

	const updateMinAgeRange = (event) => {
  	setUpdatedMinAge(event.id);
  }

	const updateMaxAgeRange = (event) => {
  	setUpdatedMaxAge(event.id);
  }

  const getFacilities = () => {
  	if (updatedFacilities !== EMPTY_STRING) {
  		return updatedFacilities;
  	} else {
  		return activityDetail?.facility !== undefined ? activityDetail?.facility : [];
  	}
  }

  const updateFacilities = (event) => {
  	if (event) {
  		let existingFacilities = getFacilities();

  		if (event.isSelected) {
  			setUpdatedFacilities([event.id, ...existingFacilities])
  		} else {
  			let newFacilities = existingFacilities.filter(facility => facility !== event.id)
  			setUpdatedFacilities(newFacilities);
  		}
  	}
  }

  const getAccessibleFacilities = () => {
  	if (updatedAccessibleFacilities !== EMPTY_STRING) {
  		return updatedAccessibleFacilities;
  	} else {
  		return activityDetail?.accessibilityFacility !== undefined ? activityDetail?.accessibilityFacility : [];
  	}
  }

  const updateAccessibleFacilities = (event) => {
  	
  		let existingFacilities = getAccessibleFacilities();

  		if (event.isSelected) {
  			setUpdatedAccessibleFacilities([event.id, ...existingFacilities])
  		} else {
  			let newFacilities = existingFacilities.filter(facility => facility !== event.id)
  			setUpdatedAccessibleFacilities(newFacilities);
  		}
  	
  }

  const updateOpeningHours = (event) => {
  	setUpdatedOpeningHours(event);
  }

  const updateContactName = (event) => {
  	setUpdatedContactName(event);
  }

  const updateContactEmail = (event) => {
  	setUpdatedContactEmail(event);
  }

  const updateContactPhone = (event) => {
  	setUpdatedContactPhone(event);
  }

  const updateContactWebsite = (event) => {
  	setUpdatedContactWebsite(event);
  }

  const onPhotoUploadComplete = (newPhoto) => {
  	if (newPhoto?.length > 0) {
  		let existingPhotos = getActivityPhotos();
  		setUpdatedPhotos([...existingPhotos, newPhoto[0]]);
  	}
  }

  const onPhotoReorderComplete = (event) => {
  	console.log(`onFileReorderComplete ${JSON.stringify(event)}`);
  	movePhotoFromSourceToTargetPosition(event.fromIndex, event.toIndex);
  }

  const movePhotoFromSourceToTargetPosition = (fromIndex, toIndex) => {
  	var photos = getActivityPhotos();
    var element = photos[fromIndex];
    photos.splice(fromIndex, 1);
    photos.splice(toIndex, 0, element);
    setUpdatedPhotos(photos);
	}

	const onPhotoRemoveComplete = (event) => {
	  	console.log(`onPhotoRemoveComplete ${JSON.stringify(event)}`);
      let p = getActivityPhotos();
      p.splice(event.fromIndex,1);
      setUpdatedPhotos(p);
	}

  const getActivityPhotos = () => {
  	if (updatePhotosRef?.current.length > 0) {
  		// used to avoid missing uploads when multiple file are uploaded at once due to the async nature of setState and then getState
  		return updatePhotosRef?.current; 
  	} else {
  		return existingPhotos;
  	}
  }

  const activityDetailsValid = (isValid) => {
    setIsActivityDetailsValid(isValid);
  }

  return (
    <div>
    	{dataLoadError ? (
    		<div>
    			<AlertBanner hasErrored={true} hasErroredText="Error unable to load activity please try again."/>
	   		</div>
	   	) : (
 			isLoading ? (
            <Loading />
          ) : (
          	<div className="container">
          		<PlaceDetails place={activityDetail?.place} />

              <ActivityDetailsForm activity={activityDetail} onNameChange={e => updateActivityName(e)} onDescriptionChange={e => updateActivityDescription(e)} 
              onAllAgesChange={(e) => setUpdatedAllAges(e)} onMinAgeChange={(e) => updateMinAgeRange(e)} onMaxAgeChange={e => updateMaxAgeRange(e)} 
              onIsFreeChange={(e) => setUpdatedIsFree(e)} isFormValid={v => activityDetailsValid(v)} />

              <ActivityPhotos onPhotoUploadComplete={onPhotoUploadComplete} onPhotoReorderComplete={onPhotoReorderComplete} onPhotoRemoveComplete={onPhotoRemoveComplete} photos={getActivityPhotos()} /> 

            	<Categories categories={getCategories()} onChange={v => updateCategories(v)}/>
  
              <ContactInformation activity={activityDetail} onContactNameChange={e => updateContactName(e)} onContactEmailChange={e => updateContactEmail(e)} onIsEmailValidChange={e => setIsEmailValid(e)} onContactPhoneChange={e => updateContactPhone(e)} onContactWebsiteChange={e => updateContactWebsite(e)} />
  						
  						<Facilities facilities={getFacilities()} onChange={e => updateFacilities(e)}/>
  						
  						<AccessibleFacilities facilities={getAccessibleFacilities()} onChange={e => updateAccessibleFacilities(e)}/>

              <ActivityOpeningHours activity={activityDetail} onUpdateIsActivityOneOffChange={e => updateIsActivityOneOff(e)} 
                    onOneOffDateChange={e => updateActivityOneOffDate(e)} onOneOffStartTimeChange={t => updateOneOffStartTime(t)} onOneOffEndTimeChange={t => updateOneOffEndTime(t)}
                    onOpeningHoursChange={e => updateOpeningHours(e)} />

  						<div className="row">
  							<div className="col-12 text-center">
                    <TriStateButton isDisabled={!isActivityDetailsValid || !isEmailValid} onClick={() => updateActivity()} label="Save Activity">
  									 <button type="button" onClick={() => navigateToMyActitivities()} className="btn btn-secondary btn-md action-cancel text-white">Cancel</button>
  				          </TriStateButton>
                    
                </div>
  			    	</div>
  			   		<AlertBanner hasSuccess={hasSavedActivity} successText="Activity updated successfully" hasErrored={hasErroredActivity} hasErroredText="Error unable to update activity"/>
  			    	<hr />
  					</div>

 				))}
    </div>
  );
}

export default ActivityDetail




