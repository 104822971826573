import React from 'react';

export default function SortableImage(props) {
  const API_URL = process.env.REACT_APP_BACKEND_URL;

  const getImageUrl = (path) => {
    if (path?.length >= 1) {
      return `${API_URL}${path.substring(1, path.length)}`
    }
  }
  
  return (
    <img alt={props?.name} src={getImageUrl(props?.url)} className="thumbnail-image" /> 
  );
}