import React from 'react'

export function Footer() {
  
  return (
  		<footer className="container pt-5">
        <div className="footer">
          <a className="nav-link link" href="https://api.villije.co/privacy.html">Privacy</a>
          <p>Villije® copyright {new Date().getFullYear()}. All rights reserved.</p>
        </div>
      </footer>
  )
}

export default Footer
