import React from "react";

import Activity from "./activity.component";

const ActivityCollection = ({id, key, collection}) => {
  
  const activities = collection.activities;
  
    return (
      <div class="row">
        <h1 class="display-6">{collection.name}</h1>
        <p class="lead">{collection.description}</p>

        {activities.map((activity, idx) => {
          return (
            
            <div key={`${id}-div-${idx}`} class="col">
              <Activity
                id={`activity-${idx}`}
                key={`${id}-activity-${idx}`}
                activity={activity}
                collection={collection.name}
                index={idx}
              />
            </div> 
          );
        })
      }
      </div>
      );
}

export default ActivityCollection
