import React from 'react'
import { BiFace } from 'react-icons/bi';

export function AgeRange({minAge, maxAge}) {
  
  const getAgeRangeValueOrDefault = () => {
    let minNotEmpty = minAge !== undefined
    let maxNotEmpty = maxAge !== undefined
    let valuesEqual = minAge === maxAge;
    if (minAge <= 0 && maxAge >= 16) {
      return 'All ages';
    }
    if (minNotEmpty && maxNotEmpty && !valuesEqual) {
      return minAge + '-' + maxAge + ' years';
    }
    if (minNotEmpty && maxNotEmpty && valuesEqual) {
      return minAge + '+ years';
    }
    if (minNotEmpty && !maxNotEmpty) {
      return minAge + '+ years';
    }
    if (!minNotEmpty && maxNotEmpty) {
      return maxAge + '+ years';
    }
    if (!minNotEmpty && maxNotEmpty) {
      return maxAge + '+ years';
    }
    return '';
  };

  const hasAges = () => {
  	return minAge !== undefined && maxAge !== undefined
  }

  return (
    hasAges ? (
    	<div class="age-range-container">
    		<div class="age-range-icon"><BiFace size={18}/></div>
 				<p>{getAgeRangeValueOrDefault()}</p>
    	</div> ) : null
  )
}

export default AgeRange
