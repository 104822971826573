import React, { Component } from "react";

import ActivityService from "../services/activity.service";
import AuthService from "../services/auth.service";

import MyActivitiesEntry from "./my-activities-entry.component";
import Loading from "./loading.component";
import Headline from './headline.component';


export default class MyActivities extends Component {
  constructor(props) {
    super(props);
    this.loadMyActivities = this.loadMyActivities.bind(this);
    
    this.state = {
      myActivities: [],
      loading: true
      
    };
  }

  loadMyActivities(userId) {
   
    this.setState({
      myActivities: [],
      loading: true
    });

    ActivityService.getMyActivities(userId).then(
      response => {
        this.setState({
          myActivities: response.data,
          loading: false
        });
      }
    );
    
  }

  componentDidMount() {
    const userId = AuthService.getUserId();

    if (!userId) this.setState({ redirect: "/login" });

    this.loadMyActivities(userId);
  }


  render() {
    const {myActivities, loading} = this.state;

    return (
      <div className="container">
        <Headline label="My Activities"></Headline>
        <table className="table table-hover align-middle">
          <thead>
            <tr>
              <th></th>
              <th>Place</th>
              <th>Activity Name</th>
              <th>Description</th>
              <th>Reviews</th>
              <th>Created On</th>
              <th>Updated On</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
          {myActivities.map((data, key) => {
              return (
                  <MyActivitiesEntry
                    id={`my-ctivities-${key}`}
                    key={`my-ctivities-${key}`}
                    activity={data}
                  />
           
              );
            })
          }
          </tbody>
        </table>
        {loading && (
            <Loading />
          )}
      </div>
    );
  }
}



