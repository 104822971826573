import React, {useState} from "react";

import { AiOutlinePhone } from 'react-icons/ai';

import Headline from './headline.component';

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const ContactInformation = (props) => {

	const [activity] = useState(props?.activity);
	const [contactName, setContactName] = useState(activity?.contactName);
	const [contactEmail, setContactEmail] = useState(activity?.contactEmail);
	const [isEmailValid, setIsEmailValid] = useState(true);
	const [contactPhone, setContactPhone] = useState(activity?.contactPhone);
	const [url, setUrl] = useState(activity?.url);

	const updateContactName = event => {
		if (props.onContactNameChange) {
			props.onContactNameChange(event?.target?.value);
		}
	}

	const updateContactEmail = event => {
		let value = event?.target?.value;
		setContactEmail(value);
		let isValid = true;
		if (value?.length > 0) {
			isValid = EMAIL_REGEX.test(value);
		}
		setIsEmailValid(isValid)
		if (props.onIsEmailValidChange) {
			props.onIsEmailValidChange(isValid);
		}
		if (props.onContactEmailChange) {
			props.onContactEmailChange(value);
		}

	}

	const updateContactPhone = event => {
		if (props.onContactPhoneChange) {
			props.onContactPhoneChange(event?.target?.value);
		}
	}

	const updateContactWebsite = event => {
		if (props.onContactWebsiteChange) {
			props.onContactWebsiteChange(event?.target?.value);
		}
	}

	const getIsEmailValid = () => {
		if (contactEmail?.length > 0) {
			return isEmailValid ? "" : "is-invalid";
		} else {
			return "";
		}

	}

    return (
    	<div>
    		<Headline label="Contact Information"><AiOutlinePhone size={30} /></Headline>	
			<div className="row">
				<div className="col">
					<form className="form-floating mb3">
						  <input type="text" onChange={e => updateContactName(e)} className="form-control" id="contactName" placeholder="Contact name for activity" defaultValue={contactName}/>
						  <label htmlFor="contactName">Contact name</label>
					</form>
				</div>
				<div className="col">
					<div className="input-group has-validation">
						<form className="form-floating mb3">
							  <input type="email" onChange={e => updateContactEmail(e)} className={`form-control ${getIsEmailValid()}`} id="contactEmail" placeholder="Contact email address" defaultValue={contactEmail}/>
							  {isEmailValid ? (
								<label htmlFor="contactEmail">Contact email</label>
							  ) : (
						  		<label className="validation-error-label" htmlFor="contactEmail">Email address is not valid</label>
						  	  )}
						</form>
					</div>
				</div>
			</div> 		
			<div className="row">
				<div className="col">
					<form className="form-floating mb3 has-validation">
						  <input type="text" onChange={e => updateContactPhone(e)} className="form-control" id="contactPhone" placeholder="Contact phone" defaultValue={contactPhone}/>
						  <label htmlFor="contactPhone">Contact phone</label>
					</form>
				</div>
				<div className="col">
					<form className="form-floating mb3">
						  <input type="text" onChange={e => updateContactWebsite(e)} className="form-control" id="website" placeholder="Website" defaultValue={url}/>
						  <label htmlFor="website">Website</label>
					</form>
				</div>
			</div>
    	</div>
      
    );
}

export default ContactInformation

