import React from 'react'
import { Rating } from 'react-simple-star-rating'

export function Ratings({score, count}) {
  
  return (
  		<div>
	  			<div>
			      <Rating
			        initialValue={score}
			        readonly
			        tooltipDefaultText="Villije community rating"
			        size={18}
			        allowFraction={true}
			      />
		      <div>
		      	{count !== undefined ? <p className="ratings-count">({count})</p> : null}
		      </div>
		     </div>
	    </div>
  )
}

export default Ratings
