import React, { useState, useRef, useEffect } from 'react'
import authHeader from './../services/auth-header';

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);

const API_URL = process.env.REACT_APP_BACKEND_URL;

export function FileUploader({photos, onFileUploadComplete, onFileReorderComplete, onFileRemoveComplete}) {

  const [files, setFiles] = useState([]);
  const pondRef = useRef();

  useEffect(() => {
  	let updatedFiles = [];
  	if (photos && files.length == 0) {
	    photos !== "no-text" && photos?.forEach(f => {
	    	updatedFiles.push({
	    		source: f.hash + f.ext,
	    		options: {
	            type: 'local',
	        },
	    	})
	    });
	    setFiles(updatedFiles);
	  }
  }, [photos]);

  const getServerOptions = () => {
  	return {
  			url: `${API_URL}`,
        process: {
            url: "upload",
            headers: authHeader(),
            onload: (response) => onUploadComplete(response)
        },
        load: {
        	url: "uploads/",
        	headers: authHeader(),
        }
      }
  }

  const onUploadComplete = (response) => {
  	let json = JSON.parse(response);
  	for (var i in json) {
  		console.log(`Upload complete ${json[i].name}`);
  	}
  	if (onFileUploadComplete) {
  		onFileUploadComplete(JSON.parse(response));
  	}
  }

  const onRemoveFile = (item) => {
  	console.log(`removed file ${JSON.stringify(item)}`)
  	var index = photos.map(photo => (photo?.hash + photo?.ext)).indexOf(item.source);
  	if (index !== -1 && onFileRemoveComplete) {
  		onFileRemoveComplete({fromIndex : index, file: item})
		}
  }

  const onReorderFiles = (files, origin, target) => {
  	if (onFileReorderComplete) {
  		onFileReorderComplete({fromIndex : origin, toIndex: target, file: files[target]})
  	}
  }

  return (
  		<div>
            <FilePond
		          ref={pondRef}
		          files={files}
		          onupdatefiles={setFiles}
		          allowMultiple={true}
		          allowReorder={true}
		          allowRevert={false}
		          allowRemove={true}
		          maxFiles={10}
		          allowFileTypeValidation={true}
		          acceptedFileTypes={['image/*']}
		          server={getServerOptions()}
		          name="files" /* sets the file input name, it's filepond by default */
		          onremovefile={(error, fileItem) => onRemoveFile(fileItem)}
		          onreorderfiles={(files, origin, target) => onReorderFiles(files, origin, target)}
		          itemInsertLocation="after"
		          labelIdle={"Drag & Drop your files or <span class=\"filepond--label-action\"> Browse </span> <p>(Reorder to make first photo the headline)</p>"}
        />
        </div>
  )
}

export default FileUploader