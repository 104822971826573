import React, {useState} from 'react'
import { FaTrashAlt } from 'react-icons/fa';

import IsOpenToggle from './is-open-toggle.component';
import TimePicker from './timepicker.component';

import * as Days from './../common/daysOfWeek';
import * as DateFormatter from './../common/date-formatter';

export function OpeningHours({openingHours, onChange}) {  

  const [updatedOpeningHours, setUpdatedOpeningHours] = useState(openingHours);
  const DEFAULT_OPEN = "T09:00:00Z";
  const DEFAULT_CLOSE = "T17:00:00Z";
  const DEFAULT_OPEN_TIME = DateFormatter.fromTimestamp(DEFAULT_OPEN);
  const DEFAULT_CLOSE_TIME = DateFormatter.fromTimestamp(DEFAULT_CLOSE);

  const findHoursInDay = day => {
    let hoursFound = updatedOpeningHours.filter(e => e.dayOfWeek === day?.id);
    return hoursFound;
  };

  const findIsOpen = day => {
    let dayFound = findOpeningHoursForDay(day);
    let isOpenResult = dayFound !== undefined ? !dayFound?.isClosed : false;
    return isOpenResult;
  };

  const findOpeningHoursForDay = day => {
    return updatedOpeningHours.find(e => e.dayOfWeek === day?.id && e.isClosed === false);
     
  }

  // return an opening time for a day unless the day is marked as closed
  const getTime = (time, defaultTime, isOpen) => {
    if (time) {
      return DateFormatter.toTimestamp(time);
    } else if (isOpen) {
      return defaultTime;
    } else {
      return undefined;
    }
  }

  const isOpenChanged = (isOpen, openingHoursForDay, dayId) => {
    console.log(`isOpenChanged from [${isOpen}] for day: [${JSON.stringify(openingHoursForDay)}] dayId: [${dayId}]`);
    let updatedOpeningHoursForDay = [];

    if (openingHoursForDay.length > 0) {
      if (isOpen) {
        openingHoursForDay.forEach(hours => {
          updatedOpeningHoursForDay = [...updatedOpeningHoursForDay, {dayOfWeek: dayId, isClosed: false, opens : DEFAULT_OPEN_TIME, closes: DEFAULT_CLOSE_TIME}]
        });
      } else {
        openingHoursForDay.forEach(hours => {
          updatedOpeningHoursForDay = [...updatedOpeningHoursForDay, {dayOfWeek: dayId, isClosed : true, opens : null, closes : null}]
        });
      }
    } else {
      //closed with no hours being updated to open
      updatedOpeningHoursForDay = [{ "isClosed":false,"dayOfWeek":dayId, opens: DEFAULT_OPEN_TIME, closes: DEFAULT_CLOSE_TIME }]
    }
    
    // Filter the updated values out from the original updatedOpeningHours then merge the filtered values
    // with the newly updated values (updatedOpeningHoursForDay)
    const filteredResult = updatedOpeningHours.filter(hours => hours.dayOfWeek !== dayId);

    const newUpdatedOpeningHours = [...filteredResult, ...updatedOpeningHoursForDay];
    
    updateOnChange(newUpdatedOpeningHours);
  }

  const updateOnChange = (event) => {
    setUpdatedOpeningHours(event);
    if (onChange) {
      onChange(event);
    }
  }

  const onOpeningTimeChanged = (event, openingHoursForDay) => {
    const newOpeningTime = DateFormatter.fromTimestamp(event);
    let newOpeningHoursForDay = openingHoursForDay;
    newOpeningHoursForDay.opens = newOpeningTime;
    updateOpeningTimesForDay(newOpeningHoursForDay);
  }

  const onClosingTimeChanged = (event, openingHoursForDay) => {
    const newClosingTime = DateFormatter.fromTimestamp(event);
    let newOpeningHoursForDay = openingHoursForDay;
    newOpeningHoursForDay.closes = newClosingTime;
    updateOpeningTimesForDay(newOpeningHoursForDay);
  }

  const updateOpeningTimesForDay = (newOpeningHoursForDay) => {
    const filteredResult = updatedOpeningHours.filter(hours => hours.dayOfWeek !== newOpeningHoursForDay.dayOfWeek);
    const newUpdatedOpeningHours = [...filteredResult, newOpeningHoursForDay];
  
    updateOnChange(newUpdatedOpeningHours);
  }

  const noInformationProvided = (openingHoursForDay) => {
      return openingHoursForDay?.length === 0 ? true : false;
  }

  const removeOpeningTime = (openingHoursForDay) => {
      const filteredResult = updatedOpeningHours.filter(hours => hours.dayOfWeek !== openingHoursForDay.dayOfWeek);
      
      updateOnChange(filteredResult);
  }

  const getOpeningHoursForDay = (day) => {
    let isOpen = findIsOpen(day);
    let openingHoursForDay = findHoursInDay(day);
    if (day.label) {
      return (
          <div id={day.label}>
            <div id={`row-${day.label}`} className="row">
              <div className="col-1">
                <p>{day.label}</p>
              </div>
              <div className="col-2">
                {noInformationProvided(openingHoursForDay) ? (
                  <IsOpenToggle key={day.id} isOpen={false} onChange={(e) => isOpenChanged(e, openingHoursForDay, day.id)} />
                  ) : (
                  <IsOpenToggle key={day.id} isOpen={isOpen} onChange={(e) => isOpenChanged(e, openingHoursForDay, day.id)} />
                )}
              </div>
              {noInformationProvided(openingHoursForDay) ? (
                  <div className="col-8">
                    <p>No information</p>
                  </div>
                ) : (
                  <div className="col-8">
                    {openingHoursForDay.map(opening => {
                        return (
                          <div id={`opening-hours-${opening?.id}`} className="row" style={{"marginBottom" : "10px"}}>
                            <div id={`opentime-${opening?.id}`} className="col-2">
                              <TimePicker key={`opentime-${opening.id}`} isEnabled={isOpen} selectedValue={getTime(opening?.opens, DEFAULT_OPEN, isOpen)} onUpdate={(e) => onOpeningTimeChanged(e, opening)} />
                            </div>
                            <div id={`closetime-${opening?.id}`} className="col-2">
                              <TimePicker key={`closetime-${opening.id}`} isEnabled={isOpen} selectedValue={getTime(opening?.closes, DEFAULT_CLOSE, isOpen)} onUpdate={(e) => onClosingTimeChanged(e, opening)} />
                            </div>
                            <div id={`trash-${opening?.id}`} className="col-1">
                              <FaTrashAlt size={20} onClick={() => removeOpeningTime(opening)}/>
                            </div>
                          </div>
                        );
                    })
                  }
                  </div> )}
            </div>
            <div className="row" >
              <div className="col-11">
                <hr />
                <p style={{"marginTop" : "-20px"}} />
              </div>
            </div>
          </div>
      );
    }
    return null;
  };

  return (
      <div className="pt-5">
        {getOpeningHoursForDay(Days.MONDAY)} 
        {getOpeningHoursForDay(Days.TUESDAY)} 
        {getOpeningHoursForDay(Days.WEDNESDAY)} 
        {getOpeningHoursForDay(Days.THURSDAY)}
        {getOpeningHoursForDay(Days.FRIDAY)}
        {getOpeningHoursForDay(Days.SATURDAY)}
        {getOpeningHoursForDay(Days.SUNDAY)}    
      </div>
  )
}

export default OpeningHours
