import React, {useState}  from 'react'

import PlaceService from "../services/place.service";
import AlertBanner from "./alert.component";
import TriStateButton from './tri-state-button.component';
import PlaceForm from './place-form.component';
function PlaceDetail({place}) {
  
  const EMPTY_STRING = "no-text";
 	const [updatedHouse, setUpdatedHouse] = useState(EMPTY_STRING);
  const [updatedPlaceName, setUpdatedPlaceName] = useState(EMPTY_STRING);
  const [updatedStreetAddress, setUpdatedStreetAddress] = useState(EMPTY_STRING);
  const [updatedLocality, setUpdatedLocality] = useState(EMPTY_STRING);
  const [updatedRegion, setUpdatedRegion] = useState(EMPTY_STRING);
	const [updatedPostcode, setUpdatedPostcode] = useState(EMPTY_STRING);
	const [updatedGeo, setUpdatedGeo] = useState(EMPTY_STRING);
  const [hasPlaceSaveSuccess, setHasPlaceSaveSuccess] = useState(false);
  const [hasPlaceSaveError, setHasPlaceSaveError] = useState(false);
  const [isPlaceDetailsValid, setIsPlaceDetailsValid] = useState(false);

  const getPlaceName = () => {
  	if (updatedPlaceName !== EMPTY_STRING) {
  		return updatedPlaceName;
  	} else {
  		return place?.name
  	}
  }

  const updatePlaceName = (data) => {
  	setUpdatedPlaceName(data.target.value);
  }

  const getHouse = () => {
  	if (updatedHouse !== EMPTY_STRING) {
  		return updatedHouse;
  	} else {
  		return place?.house
  	}
  }

  const updateHouse= (data) => {
  	setUpdatedHouse(data.target.value);
  }

  const getStreetAddress = () => {
  	if (updatedStreetAddress !== EMPTY_STRING) {
  		return updatedStreetAddress;
  	} else {
  		return place?.streetAddress
  	}
  }

  const updateStreetAddress = (data) => {
  	setUpdatedStreetAddress(data.target.value);
  }

  const getLocality = () => {
  	if (updatedLocality !== EMPTY_STRING) {
  		return updatedLocality;
  	} else {
  		return place?.addressLocality
  	}
  }

  const updateLocality = (data) => {
  	setUpdatedLocality(data.target.value);
  }

  const getRegion = () => {
  	if (updatedRegion !== EMPTY_STRING) {
  		return updatedRegion;
  	} else {
  		return place?.addressRegion
  	}
  }

  const updateRegion = (data) => {
  	setUpdatedRegion(data.target.value);
  }

  const getPostcode = () => {
  	if (updatedPostcode !== EMPTY_STRING) {
  		return updatedPostcode;
  	} else {
  		return place?.postalCode
  	}
  }

  const getLocation = () => {
  	if (updatedGeo !== EMPTY_STRING) {
  		return {
                type: 'Point',
                coordinates: [updatedGeo?.longitude, updatedGeo?.latitude],
            }
  	} else {
  		return place?.location;
  	}
  }

  const savePlace = () => {
  		setHasPlaceSaveSuccess(false);
  		setHasPlaceSaveError(false);
  		let updatedPlace = { id : place?.id,
  										name : getPlaceName(),
  										house : getHouse(),
  										streetAddress: getStreetAddress(),
  										addressLocality: getLocality(),
  										addressRegion: getRegion(),
  										postalCode: getPostcode(),
  										location: getLocation()
  								}
  		console.log(`About to save place ${JSON.stringify(updatedPlace)}`);

  		PlaceService.updatePlace(updatedPlace).then(
      response => {
       	setHasPlaceSaveSuccess(true);
      }
    ).catch(function (error) {
    	setHasPlaceSaveError(true);
    }).finally(() => {
    	//do nothing
    }); 
  }

  return (
    <div>
 			<div className="accordion accordion-flush" id="placeAccordion">
				<div className="accordion-item">
			    <h2 className="accordion-header" id="headingOne">
			      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#placeDetails" aria-expanded="true" aria-controls="placeDetails">
			        Place Details (click to edit)
			      </button>
			    </h2>
			    <div id="placeDetails" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#placeAccordion">
			      <div className="accordion-body">
			      	<PlaceForm
			      		placeName={getPlaceName()}
			      		onPlaceNameChange={e => updatePlaceName(e)}
			      		house={getHouse()}
			      		onHouseChange={e => updateHouse(e)}
			      		streetAddress={getStreetAddress()}
			      		onStreetAddressChange={e => updateStreetAddress(e)}
			      		locality={getLocality()}
			      		onLocalityChange={e => updateLocality(e)}
			      		county={getRegion()}
			      		onCountyChange={e => updateRegion(e)} 
			      		postcode={getPostcode()}
			      		onPostcodeChange={e => setUpdatedPostcode(e)} 
			      		onGeoChange={e => setUpdatedGeo(e)} 
			      		isFormValid={e => setIsPlaceDetailsValid(e)} />
			      	<div className="row">
								<div className="col-5"></div>
								<div className="col-3">	
										<button type="button" disabled={!isPlaceDetailsValid} className="btn-md action-save btn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#savePlaceModal">Save Place</button>
					      </div>
					     	<div className="col-4"></div>
					    </div>
							<div className="modal fade" id="savePlaceModal" tabIndex="-1" aria-labelledby="savePlaceModalLabel" aria-hidden="true">
							  <div className="modal-dialog modal-dialog-centered">
							    <div className="modal-content">
							      <div className="modal-header">
							        <h1 className="modal-title fs-5" id="savePlaceModalLabel">Updates to Place Details</h1>
							        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
							      </div>
							      <div className="modal-body ">
							      	<div className="model-body-text">
								        <p>All activities associated with this place will be updated after save.</p>
								        <p></p>
								        <p>Are you sure you wish to continue?</p>
								      </div>
							      </div>
							      <div className="modal-footer center">
							      	<TriStateButton fromModalId="modal" onClick={() => savePlace()} label="Save changes">
							      			<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
							      	</TriStateButton>
							      </div>
							    </div>
							  </div>
							</div>
			      </div>
			    </div>
			  </div>
			</div>
			<AlertBanner hasSuccess={hasPlaceSaveSuccess} successText="Place updated successfully" hasErrored={hasPlaceSaveError} hasErroredText="Error unable to update place"/>
			    	
    </div>
  );
}

export default PlaceDetail




