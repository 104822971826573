// converts timestamp to time portion e.g. T09:45:00Z => 09:45:00.000
export const fromTimestamp = (timestamp) => {

    const time = timestamp?.replace('T', '').replace('Z', '.000');
    return time;
}

// converts time to timestamp e.g. 09:45:00.000 => T09:45:00Z
export const toTimestamp = (time) => {

    const timestamp = 'T' + time?.replace('.000', 'Z');
    return timestamp;
}

// converts full UTC timestamp to timestamp only 2023-06-14T08:30:00.000Z => T08:30:00Z
export const fromDatetime = (datetime) => {

    const hours = datetime?.getHours().toString().padStart(2, '0');
    const minutes = datetime?.getMinutes().toString().padStart(2, '0');
    const timestamp = 'T' + hours + ':' + minutes + ':00Z';
    return timestamp;
}
 
