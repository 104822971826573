import React, {useEffect, useState} from "react";

import { BsCameraFill } from 'react-icons/bs';

import FileUploader from './file-uploader.component';
import Headline from './headline.component';

const ActivityPhotos = (props) => {

    const [existingPhotos, setExistingPhotos] = useState([]);
    
    useEffect(() =>  {
      setExistingPhotos(props?.photos);

    }, [props]);

    const onPhotoUploadComplete = (event) => {

      if (props?.onPhotoUploadComplete) {
        props?.onPhotoUploadComplete(event)
      }
    }

    const onPhotoReorderComplete = (event) => {

      if (props?.onPhotoReorderComplete) {
        props?.onPhotoReorderComplete(event)
      }
    }

    const onPhotoRemoveComplete = (event) => {

      if (props?.onPhotoRemoveComplete) {
        props?.onPhotoRemoveComplete(event)
      }
    }

    return (
      <div>
        <Headline label="Activty Photos"><BsCameraFill size={30} /></Headline>

        <div className="row">
          <div className='file-uploader-container'>
            <FileUploader onFileUploadComplete={props?.onPhotoUploadComplete} onFileReorderComplete={onPhotoReorderComplete} onFileRemoveComplete={onPhotoRemoveComplete} photos={existingPhotos} />
          </div>
        </div>
      </div>

      );
}

export default ActivityPhotos

