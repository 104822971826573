import React, {useState, useEffect} from 'react'

import GeoService from "../services/geo-lookup.service"


export function PostcodeInput({type, label, placeholder, validationError, onChange, value, limit, disabled, onIsValidChanged, onGeoChange}) {
  	const EMPTY_STRING = "no-text";
  	const [isValueValid, setIsValueValid] = useState();
  	const [geo, setGeo] = useState();

  	useEffect(() =>  {

  		const getGeo = async () => {
  			let isValid = false;
  			let location = await GeoService.getGeoFromPostcode(postcode);
  			
  			if (location) {
  				isValid = true;
  				setGeo(location);
  				if (onGeoChange) {
  					onGeoChange(location);
  				}
  			}

  			setIsValueValid(isValid);
  			if (onIsValidChanged) {
	  			onIsValidChanged(isValid);
	  		}
  		}

  		//Postcodes should be between 5 and 7 characters
  		let postcode = value?.replace(/ +/g, "");
  		if (postcode?.length >= 5 && postcode.length <= 7) {

  			getGeo()
  				.catch(function (error) {
			        setIsValueValid(false);
			        if (onIsValidChanged) {
				  		onIsValidChanged(false);
				  		}
			      })
  		}
  		
  	}, [value]);

	const getIsValid = () => {

		return isValueValid ? "" : "is-invalid";

	}

	const getLimit = () => {
		return limit ? limit : null;
	}

	const onValueChanged = (event) => {
		if (onChange) {
			onChange(event?.target?.value);
		}
	}

	return (
			<div className="input-group has-validation">
					<form className="form-floating mb3">
						<input type="text" disabled={disabled} onChange={onValueChanged} className={`form-control ${getIsValid()}`} id={label} placeholder={placeholder} value={value} required />
						
						{isValueValid ? (
						<label htmlFor={label}>{label}</label>
						) : (
					  <label className="validation-error-label" htmlFor={label}>{validationError}</label>
					 )}
				</form>
			</div>
	);
}

export default PostcodeInput
