import axios from 'axios';

class GeoService {
  
  getGeoFromPostcode(postcode) {
    return axios
    .get(`https://api.postcodes.io/postcodes/${postcode}`)
    .then(function (response) {
      const result = response.data.result;
      if (
        result &&
        result.longitude !== undefined &&
        result.latitude !== undefined
      ) {
        console.log('Full result: ' + JSON.stringify(result[0]));
        let locationDetails = {
          longitude: result.longitude,
          latitude: result.latitude,
        };
        console.log('locationDetails: ' + JSON.stringify(locationDetails));
        return locationDetails;
      } else {
        console.log('No results found');
      }
      return {};
    })
    .catch(function (error) {
      console.log('Unable to find postcode ' + error);
      throw Error(error.response.data);
    });
  }
}

export default new GeoService();
