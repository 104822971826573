import React, { Component } from "react";

import ActivityService from "../services/activity.service";
import ActivityCollection from "./activity-collection.component";
import AppStoreLinks from "./app-store-links.component";

export default class PreviewActivities extends Component {
  constructor(props) {
    super(props);
    this.loadActivities = this.loadActivities.bind(this);
    
    this.state = {
      collections: [],
    };
  }
  

  loadActivities() {
   
    this.setState({
      message: "",
      loading: true
    });

    ActivityService.getPreviewActivities().then(
      response => {
        console.log("response", response);
        this.setState({
          collections: response.data,
          loading: false
        });
        console.log("collections", this.state.collections);
      }
      );
    
  }

  componentDidMount() {
    this.loadActivities();
  }


  render() {
    const collections = this.state.collections;
    
    console.log("rendering collections", this.state.collections);
    return (
      <div>
      <h1 class="display-6">Searching for a specific activity or looking for inspiration?</h1>
      <p class="lead">Whatever the case, Villije makes it easy for you.</p>
      <AppStoreLinks />
      
      <p class="lead collection-header">Find suitable activities for your family using our filters. See some of the activities on the app.</p>  
      {collections.map((data, key) => {

          return (
              <ActivityCollection
                id={`collection-${key}`}
                key={`collection-${key}`}
                collection={data}
              />
       
          );
        })
      }
      <p class="lead">Download the app by clicking on one of the options below:</p>
      <AppStoreLinks />
      </div>
    );
  }
}



