import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_URL;

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "auth/local", {
        identifier: username,
        password
      })
      .then(response => {
        console.log(response.data);
        if (response.data.jwt) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(API_URL + "auth/local/register", {
      email: username,
      password,
      username
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  getUserId() {
    return this.getCurrentUser()?.user?.id;
  }

  registerAuthenticationRedirect() {
    console.log("registering 401 interceptor")
    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (401 === error.response.status) {
            console.log("redirecting to login page");
            window.location = '/login';
            this.logout();
            
        } else {
            return Promise.reject(error);
        }
    });
  }
}

export default new AuthService();
