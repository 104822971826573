import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_BACKEND_URL;


class PlaceService {
 
  searchMyPlaces(userId, query) {
    return axios.get(API_URL + `places?name_contains=${query}&user.id=${userId}`, { headers: authHeader() });
  }

  updatePlace(data) {
    return axios.put(API_URL + `places/${data.id}`, data, { headers: authHeader() });
  }

  create(data) {
    console.log(`creating place: ${JSON.stringify(data)}`);
    return axios.post(API_URL + `places`, data, { headers: authHeader() });
  }
}

export default new PlaceService();
