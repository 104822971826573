import React, {useState} from 'react'

import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import TimePicker from "./timepicker.component.js";
import * as DateFormatter from './../common/date-formatter';

const DEFAULT_OPEN = "T09:00:00Z";
const DEFAULT_CLOSE = "T17:00:00Z";

export function ActivityOneOffDates({
  startDate,
  endDate,
  onDateChange,
  onStartTimeChange,
  onEndTimeChange
}) {

  const [selectedDates, setSelectedDates] = useState({
      startDate: startDate !== undefined && startDate !== null ? startDate : new Date(),
      endDate:  endDate !== undefined && endDate !== null ? endDate : new Date(),
      key: 'selection',
    });
  const [startTime, setStartTime] = useState({
    id: null,
    label: null
  });
  const [endTime, setEndTime] = useState({
    id: null,
    label: null,
  });

  const onUpdate = (data, existingDates) => {
    console.log(`${JSON.stringify(data)}`);
    const newSelectedDates = {
      startDate: mergeExistingTime(data?.selection?.startDate, existingDates?.startDate),
      endDate: mergeExistingTime(data?.selection?.endDate, existingDates?.endDate),
      key: 'selection'
    }
    setSelectedDates(newSelectedDates);
    if (onDateChange) {
      onDateChange(newSelectedDates);
    }
  }

  const mergeExistingTime = (dateToUpdate, existingDate) => {
    const result = new Date(dateToUpdate);
    if (existingDate) {
      result.setHours(existingDate.getHours(), existingDate.getMinutes())
    }
    return result;
  }

  const updateStartTime = (time) => {
      setStartTime(time);
      if (onStartTimeChange) {
        onStartTimeChange(time);
      }
  }

  const updateEndTime = (time) => {
      setEndTime(time);
      if (onEndTimeChange) {
        onEndTimeChange(time);
      }
  }

  const getTime = (datetime, defaultTime) => {
    if (datetime) {
      return datetime ? DateFormatter.fromDatetime(datetime) : defaultTime;
    }
  }

  return (
    	<div>
        <div style={{"marginTop" : "30px"}} />
        <div className="row">
          <div className="col-6">
              <p style={{"paddingLeft": "100px"}} className="lead text-center">Select event start and end dates</p>
              <DateRangePicker
                ranges={[selectedDates]}
                onChange={(e) => onUpdate(e, selectedDates)}
                showPreview={true}
                showDateDisplay={false}
                showMonthAndYearPickers={true}
                showSelectionPreview={true}
                staticRanges={[]}
                inputRanges={[]}
                color={"#1FC4B4"}
                rangeColors={["#1FC4B4"]}
              />
          </div>
 
          <div className="col-2">
             <p className="lead">Select start time</p>
            <TimePicker isEnabled={true} selectedValue={getTime(startDate, DEFAULT_OPEN)} onUpdate={(e) => updateStartTime(e)} />
          </div>
          <div className="col-2">
             <p className="lead">Select end time</p>
            <TimePicker isEnabled={true} selectedValue={getTime(endDate, DEFAULT_CLOSE)} onUpdate={(e) => updateEndTime(e)} />
          </div>
        </div>
        <div style={{"marginTop" : "30px"}} />
        
    	</div>
  )
}

export default ActivityOneOffDates
