import React from "react";

import ActivityBanner from "./activity-banner.component";
import Ratings from "./ratings.component";
import FreeBanner from "./free-banner.component";
import AgeRange from "./age-range.component";


const Activity = ({id, activity, collection, index}) => {
   let url = "https://picsum.photos/200";
   const API_URL = process.env.REACT_APP_BACKEND_URL;

   if (activity.banner && activity.banner.url) {
      url = `${API_URL}${activity.banner.url.substring(1)}`; 
   }

   const getAltText = (collection, index) => {
      if (index === 0) {
        return `Kids ${collection} activities near me`;
      } else if (index === 1) {
        return `Kids ${collection} activities in Reading or Berkshire`;
      } else if (index === 2) {
        return `Kids ${collection} activities in London`;
      } else {
        return `Kids ${collection} activities`;
      }
   }
   
    return (
      
      <div class="col-sm-3">
        <div class="img-container">
          <img
          src={url}
          class="activity-img"
          alt={getAltText(collection, index)}

          />
          <div class="img-container-overlay">
            <ActivityBanner activity={activity} />
            
            <div class="extra-info-container">
              <div class="extra-info-free-banner-container">
                <FreeBanner isFree={activity.isFree} />
              </div>
              <div class="extra-info-ratings-container">
                <Ratings score={activity.reviewAverageScore} count={activity.reviewCount} />
              </div>
              <div class="extra-info-age-range-container">
                <AgeRange minAge={activity.ageRangeMin} maxAge={activity.ageRangeMax} />
              </div>
            </div>     
          </div>
          
        </div>
      </div>
   
      );
}

export default Activity

