import React from 'react'

export function AppStoreLinks() {
  
  const iOSUrl = 'https://apps.apple.com/gb/app/villije/id1582873328';
  const androidUrl = 'https://play.google.com/store/apps/details?id=com.villijeapp';
	return (
			<div>
				<div class="row center">
					<a className="ios-store-link" href={iOSUrl} target="_blank" rel="noopener noreferrer" title="iOS Store Villije App Download"> </a>				
					<a className="android-store-link" href={androidUrl} target="_blank" rel="noopener noreferrer" title="Android Playstore Villije App Download"> </a>
	
				</div>
			</div>
	);
}

export default AppStoreLinks
