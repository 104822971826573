import React from 'react'

import CheckButton from './check-button.component';

const AGES = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]

export function SelectAge({currentValue, onChange}) {

    const getPartitionedAges= () => {
    var result = [], size = 4;
    
    for (let i = 0; i < AGES.length; i += size)
    
        result.push(AGES.slice(i, i + size));

    return result;

  }

  const isChecked = (age) => {
    let result = currentValue !== null && currentValue === age;
    return result;
  }

  const RenderAgeRow = ({allAges, idx}) => {
      
      return (
        <div className="row category-row flex-nowrap">
          {allAges.map((age, key) => {
            return (
              <div key={"div-" + key} className="col-3">
                  <CheckButton id={age} label={age} checked={isChecked(age)} onChange={onChange} />
              </div>
            );
          })}
        </div>
      )
  }

  return (
      <div>
        {getPartitionedAges().map((partitionedAge, idx) => {
          return (
              <RenderAgeRow key={"partitionedAge-"+idx} allAges={partitionedAge} idx={idx} />            
          );
        })}
        </div>
  );
}

export default SelectAge
