import React from 'react'
import { BsCheckCircle, BsCircle } from 'react-icons/bs';

export function ActivityOneOff({
  isActivityOneOff,
  onChange,
}) {

  return (
    	<div>
        <p className="lead">Is the activity a one-off event?</p>
        <span style={{"paddingBottom" : "10px"}} />
        <div className="row">
          <div className="col-2">
        		<button type="button" className={isActivityOneOff ? "btn btn-primary text-white active" : "btn btn-secondary text-white" }  aria-pressed={isActivityOneOff} onClick={() => onChange(true)} data-bs-toggle="button"> 
              {isActivityOneOff ? <BsCheckCircle size={18} /> : <BsCircle size={18} />}
              <span style={{"padding": "5px"}}>Yes</span>
            </button>
            <span style={{"paddingLeft" : "20px"}} />
            <button type="button" className={!isActivityOneOff ? "btn btn-primary text-white active" : "btn btn-secondary text-white" }  aria-pressed={!isActivityOneOff} onClick={() => onChange(false)} data-bs-toggle="button"> 
              {!isActivityOneOff ? <BsCheckCircle size={18} /> : <BsCircle size={18} />}
              <span style={{"padding": "5px", "marginLeft": "10px"}}>No</span>
            </button>
          </div>
        </div>
    	</div>
  )
}

export default ActivityOneOff
